<template>
  <div class="px-5 mt-8" id="page-basic0">
    <b-row>
      <p class="mt-4" style="font-style: italic;">{{ $t('PAGES.SETTINGS.MEMBER_REQUIREMENTS_INFO') }}</p>
    </b-row>
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-card title="" class="mb-2 mt-8" hide-footer>
      
          <div>
            <b-form @submit="saveData">
              
              <template v-for="(item, i) in requirements">
              <RequirementRow
                v-bind:key="i"
                :requirement="item"
                @change="requirement_changed"
              />
            </template>
              <RightSaveButton
                ref="save-button"
                :text="$t('COMMON.SAVE')"
                @clicked="save_clicked()"
              />
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>

</style>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import RequirementRow from '@/view/pages/ml/settings/requirements/RequirementRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {
  name: 'MemberRequirementsSettings',
  components: {
    RequirementRow,
    RightSaveButton
  },
  mixins: [ toasts ],
  props: [],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      form: {
        company_id: '',
        constreq: []  
      },
      requirements: [
        
      ]
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadData();
      }
    }
  },
  methods: {
    requirement_changed(item) {
      for (var i = 0; i < this.requirements.length; ++i) {
        if (this.requirements[i].id === item.id) {
          this.requirements[i].checked = item.checked;
          break;
        }
      }

      this.requirements = [...this.requirements];
    },

    async save_clicked() {
      try {
        this.saveData();
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
      }

      this.$refs['save-button'].stop();
    },

    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }

      axios
        .get('/company/constreq')
        .then(res => {
          var reqs = res.data;
          this.requirements = reqs.map(req => ({
            id: req.req_id,
            name: this.$t('PAGES.SETTINGS.' + req.key),
            checked: req.value,
          }));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta medlemskrav');
        });
    },
    saveData(e) {
      if (e) {
        e.preventDefault();
      }
      

      this.form.requirements = [];

      for (var i = 0; i < this.requirements.length; ++i) {
        if (this.requirements[i].checked) {
          this.form.requirements.push(this.requirements[i].id);
        }
      }

      axios
        .put(`/company/${this.currentCompanyId}`, this.form)
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        });
    },
  }
};
</script>
